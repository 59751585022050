























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Page404 extends Vue {
  private message = '404 Page Not Found';
}
